var render = function () {
  var _vm$$route$query, _vm$investmentData, _vm$$v$investmentData, _vm$$v$investmentData3, _vm$$v$investmentData5, _vm$$v$investmentData6, _vm$investmentData2, _vm$investmentData2$c, _vm$consents, _vm$$v, _vm$$v$consents, _vm$$v$consents$priva, _vm$consents2, _vm$consents2$privacy, _vm$consents3, _vm$consents3$privacy, _vm$consents4, _vm$$v2, _vm$$v2$consents, _vm$$v2$consents$cons, _vm$consents5, _vm$consents5$consent, _vm$consents6, _vm$consents6$consent, _vm$consents7, _vm$$v3, _vm$$v3$consents, _vm$$v3$consents$cons, _vm$consents8, _vm$consents8$consent, _vm$consents9, _vm$consents9$consent, _vm$$v4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-new-investor-form"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('sygni-container-title', {
    staticClass: "mb-5"
  }, [_vm._v(_vm._s(_vm.title))]), ((_vm$$route$query = _vm.$route.query) === null || _vm$$route$query === void 0 ? void 0 : _vm$$route$query.type) === 'subscription_product' ? _c('div', {
    staticClass: "input-radios mb-5"
  }, [_c('p', {
    staticClass: "label mb-3"
  }, [_vm._v("SELECT TYPE")]), _c('div', {
    staticClass: "input-radios__container"
  }, [_c('sygni-radio', {
    attrs: {
      "group": "type",
      "radioValue": "investmentClient",
      "label": "NATURAL PERSON"
    },
    model: {
      value: _vm.type,
      callback: function callback($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  }), _c('sygni-radio', {
    attrs: {
      "group": "type",
      "radioValue": "legalEntity",
      "label": "LEGAL ENTITY"
    },
    model: {
      value: _vm.type,
      callback: function callback($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  })], 1)]) : _vm._e(), _vm.type === 'investmentClient' ? [[_c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'E-MAIL',
      "transform": "lowercase",
      "validation": _vm.$v.investmentData.email,
      "disabled": (_vm$investmentData = _vm.investmentData) === null || _vm$investmentData === void 0 ? void 0 : _vm$investmentData.status
    },
    on: {
      "blur": function blur($event) {
        return _vm.$v.investmentData.email.$touch();
      }
    },
    model: {
      value: _vm.investmentData.email,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData, "email", $$v);
      },
      expression: "investmentData.email"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'FIRST NAME',
      "validation": _vm.$v.investmentData.firstName
    },
    on: {
      "blur": function blur($event) {
        return _vm.$v.investmentData.firstName.$touch();
      }
    },
    model: {
      value: _vm.investmentData.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData, "firstName", $$v);
      },
      expression: "investmentData.firstName"
    }
  }), _c('sygni-input', {
    staticClass: "secondary",
    attrs: {
      "label": 'SURNAME',
      "validation": _vm.$v.investmentData.surname
    },
    on: {
      "blur": function blur($event) {
        return _vm.$v.investmentData.surname.$touch();
      }
    },
    model: {
      value: _vm.investmentData.surname,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData, "surname", $$v);
      },
      expression: "investmentData.surname"
    }
  })], _vm.displayField('investment_potential') || _vm.displayField('investment_knowledge') || _vm.displayField('industry') || _vm.displayField('notes') ? _c('div', {
    staticClass: "content__title"
  }, [_vm._v(" CRM Data ")]) : _vm._e(), _vm.displayField('investment_potential') ? _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.selects.investmentPotentials,
      "options-label": 'label',
      "label": 'INVESTOR POTENTIAL',
      "placeholder": 'Choose',
      "validation": (_vm$$v$investmentData = _vm.$v.investmentData) === null || _vm$$v$investmentData === void 0 ? void 0 : _vm$$v$investmentData.investmentPotential
    },
    on: {
      "blur": function blur($event) {
        var _vm$$v$investmentData2;

        (_vm$$v$investmentData2 = _vm.$v.investmentData) === null || _vm$$v$investmentData2 === void 0 ? void 0 : _vm$$v$investmentData2.investmentPotential.$touch();
      }
    },
    model: {
      value: _vm.investmentData.investmentPotential,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData, "investmentPotential", $$v);
      },
      expression: "investmentData.investmentPotential"
    }
  }) : _vm._e(), _vm.displayField('investment_knowledge') ? _c('sygni-select', {
    staticClass: "secondary",
    attrs: {
      "options": _vm.selects.investmentKnowledge,
      "options-label": 'label',
      "label": 'INVESTOR KNOWLEDGE',
      "placeholder": 'Choose knowledge',
      "validation": (_vm$$v$investmentData3 = _vm.$v.investmentData) === null || _vm$$v$investmentData3 === void 0 ? void 0 : _vm$$v$investmentData3.investmentKnowledge
    },
    on: {
      "blur": function blur($event) {
        var _vm$$v$investmentData4;

        (_vm$$v$investmentData4 = _vm.$v.investmentData) === null || _vm$$v$investmentData4 === void 0 ? void 0 : _vm$$v$investmentData4.investmentKnowledge.$touch();
      }
    },
    model: {
      value: _vm.investmentData.investmentKnowledge,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData, "investmentKnowledge", $$v);
      },
      expression: "investmentData.investmentKnowledge"
    }
  }) : _vm._e(), _vm.displayField('industry') ? _c('div', {
    staticClass: "table mt-4"
  }, [_c('div', {
    staticClass: "row mt-1"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "checkboxes-title"
  }, [_vm._v(" INDUSTRY ")]), _c('sygni-input-error', {
    staticClass: "align-left",
    attrs: {
      "useCustomError": true,
      "validation": (_vm$$v$investmentData5 = _vm.$v.investmentData) === null || _vm$$v$investmentData5 === void 0 ? void 0 : _vm$$v$investmentData5.industry
    }
  }, [_vm._v("Select at least one industry")])], 1)]), _vm._l(_vm.selects.industries, function (item, index) {
    return [index % 2 === 0 ? _c('div', {
      key: index,
      staticClass: "row mt-3"
    }, [_vm.industries[index] ? _c('div', {
      staticClass: "col-md-6 col-sm-12 checkbox-col"
    }, [_c('sygni-checkbox', {
      staticClass: "secondary",
      attrs: {
        "label": _vm.industries[index].label
      },
      model: {
        value: _vm.industries[index].value,
        callback: function callback($$v) {
          _vm.$set(_vm.industries[index], "value", $$v);
        },
        expression: "industries[index].value"
      }
    })], 1) : _vm._e(), _vm.industries[index + 1] ? _c('div', {
      staticClass: "col-md-6 col-sm-12 checkbox-col"
    }, [_c('sygni-checkbox', {
      staticClass: "secondary",
      attrs: {
        "label": _vm.industries[index + 1].label
      },
      model: {
        value: _vm.industries[index + 1].value,
        callback: function callback($$v) {
          _vm.$set(_vm.industries[index + 1], "value", $$v);
        },
        expression: "industries[index+1].value"
      }
    })], 1) : _vm._e()]) : _vm._e()];
  })], 2) : _vm._e(), _vm.displayField('notes') ? _c('sygni-text-area', {
    staticClass: "secondary mt-4",
    attrs: {
      "label": 'NOTES',
      "validation": (_vm$$v$investmentData6 = _vm.$v.investmentData) === null || _vm$$v$investmentData6 === void 0 ? void 0 : _vm$$v$investmentData6.notes
    },
    on: {
      "blur": function blur($event) {
        var _vm$$v$investmentData7;

        (_vm$$v$investmentData7 = _vm.$v.investmentData) === null || _vm$$v$investmentData7 === void 0 ? void 0 : _vm$$v$investmentData7.notes.$touch();
      }
    },
    model: {
      value: _vm.investmentData.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.investmentData, "notes", $$v);
      },
      expression: "investmentData.notes"
    }
  }) : _vm._e(), (_vm$investmentData2 = _vm.investmentData) !== null && _vm$investmentData2 !== void 0 && (_vm$investmentData2$c = _vm$investmentData2.consents) !== null && _vm$investmentData2$c !== void 0 && _vm$investmentData2$c.length ? [_vm._m(0), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [(_vm$consents = _vm.consents) !== null && _vm$consents !== void 0 && _vm$consents.privacy ? _c('sygni-checkbox', {
    staticClass: "secondary no-wrap",
    attrs: {
      "validation": (_vm$$v = _vm.$v) === null || _vm$$v === void 0 ? void 0 : (_vm$$v$consents = _vm$$v.consents) === null || _vm$$v$consents === void 0 ? void 0 : (_vm$$v$consents$priva = _vm$$v$consents.privacy) === null || _vm$$v$consents$priva === void 0 ? void 0 : _vm$$v$consents$priva.value
    },
    model: {
      value: _vm.consents.privacy.value,
      callback: function callback($$v) {
        _vm.$set(_vm.consents.privacy, "value", $$v);
      },
      expression: "consents.privacy.value"
    }
  }, [_c('p', {
    class: ['text-wrap lh-text', (_vm$consents2 = _vm.consents) !== null && _vm$consents2 !== void 0 && (_vm$consents2$privacy = _vm$consents2.privacy) !== null && _vm$consents2$privacy !== void 0 && _vm$consents2$privacy.date ? 'mb-3' : '']
  }, [_vm._v("Privacy Policy And Terms And Conditions")]), (_vm$consents3 = _vm.consents) !== null && _vm$consents3 !== void 0 && (_vm$consents3$privacy = _vm$consents3.privacy) !== null && _vm$consents3$privacy !== void 0 && _vm$consents3$privacy.date ? _c('p', {
    staticClass: "text-wrap note"
  }, [_vm._v("Last updated: " + _vm._s(_vm._f("dateWithTime")(_vm.consents.privacy.date)))]) : _vm._e()]) : _vm._e(), (_vm$consents4 = _vm.consents) !== null && _vm$consents4 !== void 0 && _vm$consents4.consent1 ? _c('sygni-checkbox', {
    staticClass: "secondary no-wrap",
    attrs: {
      "validation": (_vm$$v2 = _vm.$v) === null || _vm$$v2 === void 0 ? void 0 : (_vm$$v2$consents = _vm$$v2.consents) === null || _vm$$v2$consents === void 0 ? void 0 : (_vm$$v2$consents$cons = _vm$$v2$consents.consent1) === null || _vm$$v2$consents$cons === void 0 ? void 0 : _vm$$v2$consents$cons.value
    },
    model: {
      value: _vm.consents.consent1.value,
      callback: function callback($$v) {
        _vm.$set(_vm.consents.consent1, "value", $$v);
      },
      expression: "consents.consent1.value"
    }
  }, [_c('p', {
    class: ['text-wrap lh-text', (_vm$consents5 = _vm.consents) !== null && _vm$consents5 !== void 0 && (_vm$consents5$consent = _vm$consents5.consent1) !== null && _vm$consents5$consent !== void 0 && _vm$consents5$consent.date ? 'mb-3' : '']
  }, [_vm._v("Consent To The Processing Of Data For Marketing Purposes")]), (_vm$consents6 = _vm.consents) !== null && _vm$consents6 !== void 0 && (_vm$consents6$consent = _vm$consents6.consent1) !== null && _vm$consents6$consent !== void 0 && _vm$consents6$consent.date ? _c('p', {
    staticClass: "text-wrap note"
  }, [_vm._v("Last updated: " + _vm._s(_vm._f("dateWithTime")(_vm.consents.consent1.date)))]) : _vm._e()]) : _vm._e(), (_vm$consents7 = _vm.consents) !== null && _vm$consents7 !== void 0 && _vm$consents7.consent2 ? _c('sygni-checkbox', {
    staticClass: "secondary no-wrap",
    attrs: {
      "validation": (_vm$$v3 = _vm.$v) === null || _vm$$v3 === void 0 ? void 0 : (_vm$$v3$consents = _vm$$v3.consents) === null || _vm$$v3$consents === void 0 ? void 0 : (_vm$$v3$consents$cons = _vm$$v3$consents.consent2) === null || _vm$$v3$consents$cons === void 0 ? void 0 : _vm$$v3$consents$cons.value
    },
    model: {
      value: _vm.consents.consent2.value,
      callback: function callback($$v) {
        _vm.$set(_vm.consents.consent2, "value", $$v);
      },
      expression: "consents.consent2.value"
    }
  }, [_c('p', {
    class: ['text-wrap lh-text', (_vm$consents8 = _vm.consents) !== null && _vm$consents8 !== void 0 && (_vm$consents8$consent = _vm$consents8.consent2) !== null && _vm$consents8$consent !== void 0 && _vm$consents8$consent.date ? 'mb-3' : '']
  }, [_vm._v("Consent To The Processing Of Data Using Telecommunications Terminal Equipment And Automatic Calling Systems")]), (_vm$consents9 = _vm.consents) !== null && _vm$consents9 !== void 0 && (_vm$consents9$consent = _vm$consents9.consent2) !== null && _vm$consents9$consent !== void 0 && _vm$consents9$consent.date ? _c('p', {
    staticClass: "text-wrap note"
  }, [_vm._v("Last updated: " + _vm._s(_vm._f("dateWithTime")(_vm.consents.consent2.date)))]) : _vm._e()]) : _vm._e()], 1)])] : _vm._e()] : _vm._e(), _vm.type === 'legalEntity' ? [_c('sygni-select', {
    attrs: {
      "label": "SELECT LEGAL ENTITY",
      "options": _vm.legalEntityOptions,
      "searchable": true,
      "validation": (_vm$$v4 = _vm.$v) === null || _vm$$v4 === void 0 ? void 0 : _vm$$v4.legalEntityId
    },
    model: {
      value: _vm.legalEntityId,
      callback: function callback($$v) {
        _vm.legalEntityId = $$v;
      },
      expression: "legalEntityId"
    }
  })] : _vm._e(), _vm.viewType === 'editInvestor' ? _c('div', {
    staticClass: "add-new-investor-form__propagate"
  }, [_c('sygni-checkbox', {
    staticClass: "secondary propagate",
    attrs: {
      "value": _vm.propagate,
      "removeWatcher": true,
      "label": "PROPAGATE THE DATA"
    },
    on: {
      "changedValue": _vm.setPropagate
    }
  }), _c('p', {
    class: ['add-new-investor-form__note', !_vm.propagate ? 'hidden' : '']
  }, [_c('span', {
    staticClass: "text-danger"
  }, [_vm._v("Important!")]), _vm._v(" Remember that this action will result in changes across all Legal Entities")])], 1) : _vm._e(), _c('sygni-rounded-button', {
    staticClass: "filled gn-primary button secondary mt-5",
    on: {
      "click": function click($event) {
        return _vm.goNext();
      }
    }
  }, [_vm._v(_vm._s(_vm.nextBtnText))]), _c('router-link', {
    attrs: {
      "to": _vm.cancelBtnLink
    }
  }, [_c('div', {
    staticClass: "cancel-button mt-4"
  }, [_vm._v("Cancel")])])], 2)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row mt-1 mb-4"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "checkboxes-title"
  }, [_vm._v("CONSENTS")])])]);
}]

export { render, staticRenderFns }